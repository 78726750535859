<template>
    <div class="container">
      <div class="topImg" style="position: relative;">
        <img
            class="top"
            src="https://cdn.qiumo.net.cn/allsmarketmaterial/chaoyouPage_20/top2.png"
        />

        <div class="form" style="padding: 20px 0 20px 0;position: absolute;bottom: 15%;left: 0%;right: 0%;">
            <!-- <img
            class="step1"
            v-if="num == 1"
            src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/ydbj2_02.gif"
            /> -->
            <van-field
            v-model="form.phone_number"
            type="tel"
            maxlength="11"
            placeholder="请输入您的手机号码"
            />
            <!-- <img
            class="step2"
            v-if="num == 2"
            src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/ydbj2_04.gif"
            /> -->
            <!-- <img
            class="step3"
            v-if="num == 3"
            src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/ydbj2_05.gif"
            /> -->
            <van-field
            v-model="form.phone_code"
            type="number"
            center
            maxlength="6"
            autocomplete="one-time-code"
            placeholder="请输入6位短信验证码"
            class="input-code"
            >
            <template slot="right-icon">
                <div class="code" v-show="sendAuthCode" @click="getAuthCode">
                获取验证码
                </div>
                <div class="code" v-show="!sendAuthCode">
                <span>{{ auth_time }}秒</span>重新获取
                </div>
            </template>
            </van-field>
        </div>


        <div class="form">
            <img
            src="https://cdn.qiumo.net.cn/allsmarketmaterial/chaoyouPage_20/button.png"
            class="submit"
            @click="onSubmit"
            />
    
            <div class="checked">
                <van-checkbox
                    checked-color="#068BF7"
                    v-model="checked"
                    icon-size="12px"
                >
                    <div>我已阅读并同意《活动介绍》和《隐私条款》</div>
                </van-checkbox>

                <div style="color: rgb(67, 100, 46);">20元5G酷游包_随心选（网约车出行）</div>
                <div style="color: rgb(67, 100, 46);">资费：20元/月</div>
            </div>
        </div>
      </div>


      <div class="info">

        <img
          style="width: 100%;"
          src="https://cdn.qiumo.net.cn/allsmarketmaterial/chaoyouPage_20/center2.png"
        />


        <div class="activity">
            <img
            style="width: 35%;margin: 10px auto;display: flex;"
            src="https://cdn.qiumo.net.cn/allsmarketmaterial/chaoyouPage_20/title.png"
            />

            <img
            style="width: 100%;"
            src="https://cdn.qiumo.net.cn/allsmarketmaterial/chaoyouPage_20/text3.png"
            />
        </div>
        

        <!-- <img
          style="width: 100%;"
          src="https://cdn.qiumo.net.cn/allsmarketmaterial/vip_dx/goods.png"
        />
        <img
          style="width: 100%;"
          src="https://cdn.qiumo.net.cn/allsmarketmaterial/vip_dx/content.png"
        /> -->
        <!-- <img
          class="title"
          src="https://cdn.qiumo.net.cn/allsmarketmaterial/petroChina/activityDescription.png"
        />
        <div class="box">
          <div>
            1、中国石油加油权益包开放办理时间为2023年11月14日至2024年6月30日。
          </div>
          <div>
            2、符合活动参与条件的浙江移动普通个人手机客户，每月使用话费支付19.9元购买中国石油加油权益包，内含移动权益5元5GB夜间流量包【国内(不含港澳台)流量，限每天23时-次日08时使用】和2张220减15元加油满减券。其中:满减券每月5日前自动充值至客户绑定的中油好客e站APP或微信、支付宝小程序账户，夜间流量包每月3日前自动发放。
          </div>
          <div>
            3、订购立即生效，按月自动续订，退订次月生效。退订方式等事宜请咨询10086。
          </div>
          <div>
            4、中国石油加油权益包月费不纳入保底补收、消费抵扣范围内，不可使用赠送的话费支付。
          </div>
          <div>
            5、5GB夜间流量包流量规则说明:1)流量优先级:APP定向流量优惠流量>定点流量包/校园分区包等限定区域流量>小时包/日包/7天包>周末包/月末包>夜间包>加油包/欢孝包>套餐内通用流量>满就送流量，可时订购优先级相问的户失效时间最早的优先09)使用。2)流量结转:套餐外订购的各类流量包内含流量均仅限当月使用，不可结转，不可转赠、共享;若发生套餐变更，原套餐内含流量不可结转。
          </div>
          <div>6、中国石油加油满减券使用规则</div>
          <div>
            (1)满减券自到账之日30天内(含当日)有效，不得转赠，逾期不可补发或退回。
          </div>
          <div>
            (2)满减券限浙江省全省中国石油直营油站加油场景使用，油品标号不限，不可用于非油品消费抵扣。
          </div>
          <div>
            (3)满减券单日仅限核销1张，每张券使用单笔支付金额需大于等于220元。
          </div>
          <div>
            (4)首月订购成功后，满减券将在24小时内自动直充到中油好客e站APP或微信、支付宝小程序账户，次月起如不退订每月5日前自动到账，请登录上述APP,小程序中“电子券”查看(注:订购手机号与注册手机号需保持一致)。
          </div>
          <div>(5)用户可拨打客服电话4001189599。</div>
          <div>(6)权益一经兑换成功，不支持退换货。</div>
          <div>
            7、因权益方内部调整，我们可能会变更、终止与权益方的合作,您可能无法继续通过中国移动订购该权益方提供的，届时公司将及时公告或告知。如因此导致您无法使用或购买产品的，请您理解我们无需对此承担责任。可能给您带来的不便，敬请谅解。
          </div>
        </div> -->
      </div>
      <div style="margin-top: 10px;">
        <img
          style="width: 100%;padding: 0;margin: 0;"
          src="https://cdn.qiumo.net.cn/allsmarketmaterial/chaoyouPage_20/bottom2.png"
        />
      </div>
      <!-- 二确 -->
      <v-dialog :visibility.sync="visibility" />
    </div>
  </template>
  
  <script>
  import VDialog from "./again/index.vue";
  import { sendSMS, buy } from "@/api/wangyueche.js";
  import { generateSign, liuliangguo } from "@/utils/common";
  export default {
    components: {
      VDialog,
    },
    data() {
      return {
        form: {
          phone_number: "",
          phone_code: "",
          request_time: "",
          request_id: "",
          outer_code: "",
        },
        sendAuthCode: true,
        checked: false,
        auth_time: 0,
        state: false,
        num: 1,
        visibility: false,
        channel: "",
        userAgent: "",
      };
    },
    mounted() {
      const that = this;
  
      window.addEventListener("scroll", that.handlerscroll);
      window.onpopstate = function (event) {
        // 禁止返回上一页
        history.go(1);
        // liuliangguo("102266", that.form.phone_number, "中国石油加油权益包");
      };
    },
    created() {
      this.userAgent = navigator.userAgent;
      const { channel = "" } = this.$route.query;
      this.form = {
        ...this.form,
        callback: location.search,
      };
      let state = {
        url: document.URL,
      };
      history.pushState(state, "title", document.URL);
      this.channel = channel;
      // this.getBurialPoint();
    },
    beforeDestroy() {
      // 销毁时移除事件监听，防止影响其他页面
      window.onpopstate = null;
    },
    watch: {
      form: {
        handler(value) {
          const { phone_number = "", phone_code } = value;
          if (phone_number.length < 11) {
            this.num = 1;
          } else if (this.sendAuthCode && phone_code.length == 0) {
            this.num = 2;
          } else if (phone_code.length < 6) {
            this.num = 3;
          }
        },
        deep: true,
        immediate: true,
      },
    },
    methods: {
      async getBurialPoint() {
        const {
          errorCode = "",
          request_time = "",
          request_id = "",
        } = await burialPoint(
          {
            callback_name: "巨量",
            callback: location.search,
            auth: generateSign("GUANZHENG", "O*GF&(^&*FG(&"),
            app_name: this.userAgent,
          },
          { channel: this.channel }
        );
        if (errorCode === "000") {
          this.form = {
            ...this.form,
            request_time,
            request_id,
          };
        } else {
          this.$toast({
            duration: 8000,
            message: res.errorMsg,
          });
        }
      },
      dialogInfo() {
        this.$dialog.alert({
          messageAlign: "left",
          title: "个人信息授权保护协议",
          message: `尊敬的客户：
  您好！
  根据《中华人民共和国网络安全法》、《全国人民代表大会常务委员会关于加强网络信息保护的决定》、《电信和互联网用户个人信息保护规定》（工业和信息化部令第24号）和《电话用户真实身份信息登记规定》（工业和信息化部令第25号）等国家法律法规的要求，客户在我公司各类营业网点（含自有营业厅、手机营业厅、网上营业厅、授权合作代理店等）办理移动电话（含无线上网卡）、固定电话、家庭宽带入网、过户以及需要出示客户证件的有关业务时，客户应配合出示有效身份证件原件并进行查验、登记。登记信息包括姓名或名称、证件类型、号码及地址等。同时，为更好地提供服务，需要客户提供如联系人、联系电话、通信地址、电子邮箱等信息。客户本人持有效身份证件可通过自有营业厅查询和/或更正本人信息，或登录手机营业厅查询本人信息。如客户拒绝依法提供个人有效证件及真实信息，我公司有权不提供服务或终止服务。
   为向客户提供优质、个性化的服务，包括但不限于提供通信服务、保障通信服务安全、改善服务质量、推介个性化产品等，我公司将遵循合法、正当、必要的原则，按照法律法规规定和/或协议约定使用留存客户个人信息，并妥善保管，严格保密，依法保护客户个人信息，非因下列事由，不对外提供客户个人信息：
  （1）事先获得客户的明确授权；
  （2）根据有关的法律法规要求；
  （3）按照相关司法机关和/或政府主管部门的要求；
  （4）为维护社会公众的利益所必需且适当；
  （5）为维护我公司或客户的合法权益所必需且适当。`,
        });
      },
      handleRule() {
        this.$router.push({
          path: "/monthlyHotTopicsNTo2_YunXian/rule",
        });
      },
      async getAuthCode() {
        let filterPhone = /^1[3456789]\d{9}$/;
        const {
          phone_number = "",
          request_time = "",
          request_id = "",
        } = this.form;
        if (!filterPhone.test(phone_number)) {
          this.$dialog.alert({ message: "手机号错误" });
          return;
        }
        else if (!this.checked) {
          this.$dialog.alert({ message: "请勾选并同意相关协议" });
          return;
        }

        // 设置倒计时秒。因为后端的接口返回有点慢，为了防止用户连点，直接点击就开始倒计时
        this.sendAuthCode = false;
        this.auth_time = 120;
        let auth_timetimer = setInterval(() => {
          this.auth_time--;
          if (this.auth_time <= 0) {
            this.sendAuthCode = true;
            clearInterval(auth_timetimer);
          }
        }, 1000);

        const res = await sendSMS(
          {
            product_id: "31901001",
            phone_number,
            // callback_name: "巨量",
            default_app_name: "巨量引擎",
            // default_package_name: "巨量",
            app_name: this.userAgent,
            // callback: location.search,
            // request_time,
            // request_id,
            auth: generateSign("GUANZHENG", "O*GF&(^&*FG(&"),
          }
        );

        if (res.errorCode === "000") {
          this.form = {
            ...this.form,
            // request_time,
            // request_id,
            response_order_id: res.response_order_id,
          };
        } else {
          this.$dialog.alert({ message: res.errorMsg });
          // liuliangguo("102264", this.form.phone_number, "中国石油加油权益包");
          // 停止倒计时，变成获取验证码
          this.sendAuthCode = true;
          clearInterval(auth_timetimer);
        }
      },
      onSubmit() {
        let filtercode = /^\d{6}$/;
        let filterPhone = /^1[3456789]\d{9}$/;
        console.log("aaaaaaa",this.form.phone_code)
        if (!this.form.phone_number) {
          this.$dialog.alert({ message: "请输入手机号" });
        } else if (!filterPhone.test(this.form.phone_number)) {
          this.$dialog.alert({ message: "手机号错误" });
        } else if (!this.form.phone_code) {
          this.$dialog.alert({ message: "请输入验证码" });
        } else if (!filtercode.test(this.form.phone_code)) {
          this.$dialog.alert({ message: "请输入6位验证码" });}
        else if (!filtercode.test(this.form.phone_code)) {
          this.$toast.fail("验证码不正确");
        } else if (!this.checked) {
          this.$dialog.alert({ message: "请勾选并同意相关协议" });}
        else {
          this.visibility = true;
        }
      },
      async handleSubmit() {
        this.$toast.loading({
          duration: 0,
          forbidClick: true,
          mask: true,
          message: "加载中...",
        });
        const {response_order_id = ''} = this.form;
        const res = await buy(
          { ...this.form, auth: generateSign("GUANZHENG", "O*GF&(^&*FG(&"),response_order_id },
          { channel: this.channel }
        );
        this.visibility = false;
        this.$toast({
          duration: 8000,
          message: res.errorMsg,
        });
        if (res.errorCode === "000") {
          // liuliangguo("102265", this.form.phone_number, "中国石油加油权益包");
        } else {
          // liuliangguo("102264", this.form.phone_number, "中国石油加油权益包");
        }
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .container {
    position: relative;
    background-color: rgb(221, 236, 203);
    .top {
      width: 100%;
    }
  
    .form {
      position: relative;
    //   position: absolute;
    //   bottom: -24%;
    //   left: 0;
    //   right: 0;

      .step1,
      .step2,
      .step3 {
        width: 326px;
        position: absolute;
        z-index: 1;
        pointer-events: none;
      }
  
      .step1 {
        transform: translate(80px, -80px);
      }
  
      .step2 {
        width: 140px;
        right: 60px;
        top: 150px;
      }
  
      .step3 {
        width: 450px;
        transform: translate(84px, -20px);
      }
  
      ::v-deep .van-cell {
        width: 680px;
        height: 106px;
        background-color: #ffffff;
        margin: 0 auto 0;
        overflow: hidden;
        box-sizing: border-box;
        padding: 0 0 0 50px;
        border-radius: 20px;
        border: solid 3px rgb(67, 100, 46);
        box-sizing: border-box;
  
        &.input-code {
          margin-top: 25px;
  
          .code {
            border-left: solid 3px rgb(67, 100, 46);
            width: 252px;
            height: 106px;
            text-align: center;
            line-height: 106px;
            color: #fff;
            font-size: 34px;
            font-weight: normal;
            background-color: rgba(94,175,242,1);
            // background-color: #ff840d;
          }
        }
  
        .van-field__body {
          height: 100%;
  
          input {
            height: 100%;
          }
        }
  
        &::after {
          border: none;
        }
  
        .van-field__control {
          color: #4d2f1c;
          font-weight: normal;
          &::-webkit-input-placeholder {
            font-size: 36px;
            font-weight: 40;
            color: #969696;
          }
        }
      }
  
      .submit {
        width: 654px;
        display: block;
        margin: 0px auto 0;
        animation: identifier 1s infinite;
        @keyframes identifier {
          0% {
            transform: scale(1);
          }
          50% {
            transform: scale(0.9);
          }
          100% {
            transform: scale(1);
          }
        }
      }
  
      .checked {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 24px;
        font-weight: normal;
        color: #ffffff;
        flex-wrap: wrap;
  
        ::v-deep .van-checkbox__label {
          color: rgb(67, 100, 46);
        }
  
        .book {
          color: #ffffff;
        }
      }
    }
  
    .info {
      margin: 0 10px;
      background-color: rgb(221, 236, 203);
      // margin-top: 50px;
      .title {
        width: 251px;
        display: block;
        padding: 81px 0 24px 0;
        margin: 0 auto;
      }
  
      .box {
        padding: 0 28px 100px;
        font-size: 24px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 36px;
        letter-spacing: 0px;
        color: #ffffff;
      }
    }
  }

  .scroll_tips {
    position: absolute;
    top: 20%;
    width: 100%;
    margin: 0 auto 0;
    overflow: hidden;

    .scroll_box {
      height: 56px;
      animation: scroll 15s linear infinite;
      display: flex;

      .scroll_item {
        background-color: rgba(0, 0, 0, 0.44);
        border-radius: 40px;
        white-space: nowrap;
        width: 329px;
        text-align: center;
        font-weight: 500;
        flex-shrink: 0;
        line-height: 56px;
        font-size: 30px;
        color: white;
        margin-left: 62px;
      }

      @keyframes scroll {
        0% {
          transform: translateX(0%);
        }
        100% {
          transform: translateX(-317.2%);
        }
      }
    }
  }
  </style>  